import React from "react";
import PlesyEl from "../components/plesyEl";


const PlesyVideo = () => {

    return (
        <PlesyEl />
    )
}

export default PlesyVideo
